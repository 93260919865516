export class PurchaseOrderRequest {
  constructor(employeeId, clientId, clientDiscount, total, type, products, payments) {
    this.employeeId = employeeId;
    this.clientId = clientId;
    this.clientDiscount = clientDiscount;
    this.total = total;
    this.products = products;
    this.payments = payments;
    this.type = type;
  }
}
