import Formatter from './CurrencyFormatter';

export class ProductListItem {
  constructor(id, name, barcode, baseCost, images, discountSpecials, comboSpecials, model, brand,
    keys, inventoryQty) {
    this.id = id;
    this.name = name;
    this.barcode = barcode;
    /** @type float */
    this.baseCost = baseCost;
    this.image = images ? images[0] : null;
    this.discountSpecials = discountSpecials;
    this.comboSpecials = comboSpecials;
    this.calculatedDiscount = 0;
    this.giftCost = Formatter.format(0);
    this.isGift = 0;
    this.quantity = 1;
    this.model = model;
    this.brand = brand;
    this.keys = keys;
    this.inventoryQty = inventoryQty ? inventoryQty.quantity : 0;
  }

  get autocompleteLabel() {
    if (!this.barcode) {
      return `${this.name} - (${this.inventoryQty})`;
    } if (!this.name) {
      return `${this.barcode} - (${this.inventoryQty})`;
    } if (!this.inventoryQty) {
      return `${this.barcode} - ${this.name}`;
    }
    return `${this.barcode} - ${this.name} - (${this.inventoryQty})`;
  }

  get costStr() {
    // return Formatter.format(this.baseCost);
    const totalCost = this.baseCost * this.quantity;
    return Formatter.format(totalCost);
  }

  get discountStr() {
    return Formatter.format(this.calculatedDiscount);
  }

  get imageUrl() {
    if (!this.image) {
      // todo: set own placeholder image
      return 'https://via.placeholder.com/250';
    }
    return `${process.env.VUE_APP_BASEURL}/${this.image.filename}`;
  }

  get brandName() {
    if (this.brand) {
      return this.brand.name;
    }
    return 'Sin marca';
  }
}
