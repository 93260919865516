<template>
  <v-app>
    <app-navigation v-if="!['login'].includes($route.name)"></app-navigation>
    <router-view></router-view>
    <!-- <router-link to="/dashboard"></router-link> -->
  </v-app>
</template>

<script>
import AppNavigation from '@/components/AppNavigation.vue';

export default {
  name: 'App',

  components: {
    AppNavigation,
  },

  data: () => ({
    stompClient: null,
  }),
  mounted() {
    let wsIPAddress = localStorage.getItem('printerIPAddress');
    if (!wsIPAddress) {
      wsIPAddress = '127.0.0.1';
    }
    const ignoreInventory = localStorage.getItem('ignoreInventory');
    if (!ignoreInventory) {
      localStorage.setItem('ignoreInventory', 0);
    }
    const productsSearchType = localStorage.getItem('productsSearchType');
    const showDiscountInLine = localStorage.getItem('showDiscountInLine') === '1';

    this.$store.dispatch('setPrinterIPAddress', wsIPAddress);
    this.$store.dispatch('setProductsSearchType', productsSearchType);
    this.$store.dispatch('setShowDiscountInLine', showDiscountInLine);
  },
};
</script>

<style>
body, html {
  /* height: 100vh;
  overflow: hidden; */
}
.swal2-popup {
  font-family: 'Roboto', sans-serif !important;
}
</style>
