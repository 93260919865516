import Vue from 'vue';
import VueResizeText from 'vue-resize-text';
import VueBarcodeScanner from 'vue-barcode-scanner';
import VCurrencyField from 'v-currency-field';
import VueLodash from 'vue-lodash';
import lodash from 'lodash';
// import VueNativeSock from 'vue-native-websocket';
// import VueStomp from 'vue-stomp';
import App from './App.vue';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';
import 'roboto-fontface/css/roboto/roboto-fontface.css';
import '@mdi/font/css/materialdesignicons.css';

// set default printer ip address (if null), to localhost
const printerIPAddress = localStorage.getItem('printerIPAddress');
if (!printerIPAddress) {
  localStorage.setItem('printerIPAddress', '127.0.0.1');
}

// set default config for products search
const productsSearchType = localStorage.getItem('productsSearchType');
if (!productsSearchType) {
  localStorage.setItem('productsSearchType', 'remote');
}

// set default config for show discount in line setting
const showDiscountInLine = localStorage.getItem('showDiscountInLine');
if (!showDiscountInLine) {
  localStorage.setItem('showDiscountInLine', '0');
}

Vue.config.productionTip = false;
Vue.use(VueBarcodeScanner);
Vue.use(VueResizeText);
Vue.use(VCurrencyField, {
  locale: 'en-US',
  decimalLength: 2,
  autoDecimalMode: true,
  min: null,
  max: null,
  defaultValue: 0,
});
Vue.use(VueLodash, { lodash });
// Vue.use(VueNativeSock, 'ws://localhost:15674/ws', { login: 'guest' });
// Vue.use(VueStomp, 'ws://localhost:15674/ws');

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount('#app');

// vm.$connect();
