import CurrencyFormatter from '../CurrencyFormatter';
import { PurchaseOrderSingleItem } from '../PurchaseOrderSingleItem';

export class CashCloseData {
  constructor(totalAmount, cashAmount, debitCardAmount, creditCardAmount,
    checkAmount, transferAmount, orders, cashStartAmount) {
    this.totalAmount = totalAmount;
    this.cashAmount = cashAmount;
    this.debitCardAmount = debitCardAmount;
    this.creditCardAmount = creditCardAmount;
    this.checkAmount = checkAmount;
    this.transferAmount = transferAmount;
    this.cashStartAmount = cashStartAmount;
    this.orders = [];
    orders.forEach((x) => {
      const order = new PurchaseOrderSingleItem(x.id, x.employee, x.client, x.total, x.type,
        x.createdDt, x.isClosed, x.cashPayments, x.debitCardPayments, x.creditCardPayments,
        x.checkPayments, x.transferPayments, x.products, x.realCashPayments);
      this.orders.push(order);
    });
  }

  get totalAmountStr() {
    return CurrencyFormatter.format(this.totalAmount);
  }

  get cashAmountStr() {
    return CurrencyFormatter.format(this.cashAmount);
  }

  get debitCardAmountStr() {
    return CurrencyFormatter.format(this.debitCardAmount);
  }

  get creditCardAmountStr() {
    return CurrencyFormatter.format(this.creditCardAmount);
  }

  get checkAmountStr() {
    return CurrencyFormatter.format(this.checkAmount);
  }

  get transferAmountStr() {
    return CurrencyFormatter.format(this.transferAmount);
  }

  get cashStartAmountStr() {
    return CurrencyFormatter.format(this.cashStartAmount);
  }
}
