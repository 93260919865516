<template>
  <div>
    <v-toolbar color="primary">
      <v-app-bar-nav-icon dark @click.stop="drawer = !drawer">
      </v-app-bar-nav-icon>
    </v-toolbar>

    <v-navigation-drawer v-model="drawer" absolute temporary>
      <v-list-item>
        <v-list-item-avatar>
          <v-img src="../assets/no-pic.png"></v-img>
        </v-list-item-avatar>

        <v-list-item-content v-if="authUserData">
          <v-list-item-title>
            {{authUserData.user_claims.firstname}} {{authUserData.user_claims.lastname}}
          </v-list-item-title>
          <v-list-item-subtitle>
            {{ storeName }}
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>

      <v-divider></v-divider>

      <v-list>
        <v-list-item to="/pos" v-if="canViewCarrito">
          <v-list-item-icon>
            <v-icon>mdi-point-of-sale</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>Carrito</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item v-if="canViewPos" to="/cash-closing">
          <v-list-item-icon>
            <v-icon>mdi-cash-register</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>Corte</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item v-if="canViewPos" to="/cash-start">
          <v-list-item-icon>
            <v-icon>mdi-cash-register</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>Inicio de caja</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item v-if="canViewPos" to="/configuration">
          <v-list-item-icon>
            <v-icon>mdi-settings</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>Configuraci&oacute;n</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item @click="logout">
          <v-list-item-icon>
            <v-icon>mdi-logout</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>Salir</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { TokenUtils } from '@/utils/TokenUtils';
import UserService from '@/services/UserService';

const computed = {
  ...mapGetters({
    authUserData: 'authUserData',
    canViewCarrito: 'canViewCarrito',
    canViewPos: 'canViewPos',
  }),
};

export default {
  computed,
  name: 'app-navigation',
  data: () => ({
    drawer: null,
    storeName: '',
  }),
  methods: {
    logout() {
      localStorage.clear();
      this.$router.push('/login');
    },
  },
  async mounted() {
    const payload = TokenUtils.getJwtData();
    if (!payload || !payload.user_claims) {
      this.$router.push('/login');
      return;
    }

    this.storeName = localStorage.getItem('storeName');
    const ticketJson = localStorage.getItem('ticket');

    if (payload && (!this.storeName || !ticketJson)) {
      this.$router.push('/login');
      return;
    }

    if (payload) {
      this.$store.dispatch('setTicketData', JSON.parse(ticketJson));
      this.$store.dispatch('setAuthUserData', payload);

      const { identity: userId } = TokenUtils.getJwtData();
      const attributes = await UserService.getUserAttributes(userId);
      this.$store.dispatch('setAttributes', attributes);
    }
  },
};
</script>
