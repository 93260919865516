import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'login',
    component: () => import(/* webpackChunkName: "about" */ '../views/Login.vue'),
  },
  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "about" */ '../views/Login.vue'),
  },
  {
    path: '/pos',
    name: 'pos',
    component: () => import(/* webpackChunkName: "about" */ '../views/POS.vue'),
  },
  {
    path: '/configuration',
    name: 'configuration',
    component: () => import('../views/Configuration.vue'),
  },
  {
    path: '/cash-closing',
    name: 'cash-closing',
    component: () => import('../views/CashClosing.vue'),
  },
  {
    path: '/cash-start',
    name: 'cash-start',
    component: () => import('../views/CashStart.vue'),
  },
];

const router = new VueRouter({
  mode: 'history',
  routes,
});

export default router;
