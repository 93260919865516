import CurrencyFormatter from '../CurrencyFormatter';

export class PurchaseOrderProductItem {
  constructor(costDiscount, currentCost, product, isGift) {
    this.costDiscount = costDiscount;
    this.currentCost = currentCost;
    this.product = product;
    this.isGift = isGift;
    this.comboId = 0;
    this.model = '';
    this.includeTaxes = 1;
    this.alreadyInList = false;
  }

  get costDiscountStr() {
    return CurrencyFormatter.format(this.costDiscount);
  }

  get currentCostStr() {
    return CurrencyFormatter.format(this.currentCost);
  }

  transferTaxes(quantity) {
    if (this.includeTaxes && quantity > 0) {
      const total = this.currentCost * quantity;
      const baseTotal = Number(Number(total / 1.16).toFixed(2));
      return Number(Number(baseTotal * 0.16).toFixed(2));
    }

    return 0;
  }
}
