import { ProductListItem } from './ProductListItem';

export class SavedQuotationData {
  constructor(id, total, clientId, products) {
    this.id = id;
    this.total = total;
    this.clientId = clientId;
    this.products = [];
    products.forEach((x) => {
      const element = x.product;
      const item = new ProductListItem(element.id, element.name, element.barcode,
        element.baseCost, element.images, element.discountSpecials, element.comboSpecials,
        element.model, element.brand, element.keys, element.inventoryProduct[0]);
      this.products.push(item);
    });
  }
}
