import Vue from 'vue';
import VueLodash from 'vue-lodash';
import lodash from 'lodash';
import Vuex from 'vuex';
import Stomp from 'webstomp-client';
import Swal from 'sweetalert2';
import { makeFrontItemFromItem } from '@/serializers/PurchaseOrder.serializer';
import { ComboListItem } from '@/models/ComboListItem';
import { FPUtils } from '@/utils/FloatingPointUtils';
import CurrencyFormatter from '../models/CurrencyFormatter';
import PurchaseOrderService from '../services/PurchaseOrderService';

Vue.use(Vuex);
Vue.use(VueLodash, { lodash });

/**
 * @typedef ComboStruct
 * @type {object}
 * @property {string} id
 * @property {number} comboId
 * @property {number} buyId
 * @property {number} buyAmount
 * @property {number} giftId
 * @property {number} giftAmount
 */

/**
 * @typedef ComboItemStruct
 * @type {object}
 * @property {number} index
 * @property {number} itemId
 * @property {number} comboId
 * @property {boolean} isGift
 * @property {number} completedComboId
 */

/**
 * @typedef ItemListStruct
 * @type {object}
 * @property {number} comboId
 * @property {number} isGift
 * @property {boolean} alreadyInList
 */

/**
 * @param items
 * @returns {ComboStruct[]}
 */
function getCombosFromItems(items) {
  // calculate combos array
  // eslint-disable-next-line no-unused-vars
  let combos = items.map((item, idx) => {
    if (!!item.comboSpecials) {
      return item.comboSpecials.map((item2) => ({
        id: `${item.id}-${item2.buyAmount}-${item2.giftProduct.id}-${item2.giftAmount}`,
        comboId: item2.id,
        buyId: item.id,
        buyAmount: item2.buyAmount,
        giftId: item2.giftProduct.id,
        giftAmount: item2.giftAmount,
      }));
    }

    return [];
  });

  // concat all combos arrays into one-level array
  combos = [].concat(...combos);

  // return unique-combos array
  const combosMap = new Map();
  combos.forEach((item) => combosMap.set(item.id, item));

  return [...combosMap.values()];
}

/**
 * @param {ComboStruct} combo
 * @param {ComboItemStruct[]} items
 * @param {any[]} appendItems
 * @returns {[]}
 */
function extractCombosFromItems(combo, items, appendItems) {
  /** @var {ComboItemStruct[]} tempItems */
  const tempItems = Array.from(items);

  // eslint-disable-next-line
  const { buyId, buyAmount, giftId, giftAmount } = combo;
  let completedCombo = null;

  // eslint-disable-next-line
  const itemsToBuy = tempItems.filter((ti) => ti.itemId === buyId).slice(0, buyAmount);
  // eslint-disable-next-line
  const itemsToGift = tempItems.filter((ti) => ti.itemId === giftId && itemsToBuy.indexOf(ti) === -1).slice(0, giftAmount);

  if (itemsToBuy.length === buyAmount && itemsToGift.length === giftAmount) {
    itemsToGift.forEach((item) => {
      // noinspection UnnecessaryLocalVariableJS
      const item2 = item;
      item2.isGift = true;
    });
    completedCombo = { combo, items: [...itemsToBuy, ...itemsToGift] };

    // remove items from tempItems
    itemsToBuy.forEach((item) => tempItems.splice(tempItems.indexOf(item), 1));
    itemsToGift.forEach((item) => tempItems.splice(tempItems.indexOf(item), 1));
  }

  // if not completed combo, return appendItems
  if (!completedCombo) {
    return appendItems;
  }

  // append completed combo to items
  appendItems.push(completedCombo);

  // if can complete more items, recurse function
  const pendingItems = tempItems.filter(({ itemId }) => itemId === buyId || itemId === giftId);
  if (pendingItems.length > 0) {
    return extractCombosFromItems(combo, tempItems, appendItems);
  }

  // else, return appended items
  return appendItems;
}

/**
 * @param {ComboItemStruct} item
 * @param {number} comboId
 */
function setComboId(item, comboId) {
  // noinspection UnnecessaryLocalVariableJS
  const item2 = item;
  item2.comboId = comboId;
}

/**
 * @param {ComboItemStruct} item
 * @param {number} completedComboId
 */
function setCompletedComboId(item, completedComboId) {
  // noinspection UnnecessaryLocalVariableJS
  const item2 = item;
  item2.completedComboId = completedComboId;
}

/**
 * @param {ItemListStruct} item
 */
function resetComboItems(item) {
  // noinspection UnnecessaryLocalVariableJS
  const item2 = item;
  item2.comboId = 0;
  item2.isGift = 0;
}

/**
 * @param {ItemListStruct} item
 */
function setAlreadyInList(item) {
  const item2 = item;
  item2.alreadyInList = true;
}

/**
 * @param {ItemListStruct} item
 */
function clearAlreadyInList(item) {
  const item2 = item;
  item2.alreadyInList = false;
}

export default new Vuex.Store({
  modules: {},
  state: {
    attributes: [],
    productsList: [],
    itemsList: [],
    clientsList: [],
    itemToPreview: null,
    selectedClient: null,
    authUserData: null,
    cashCloseData: null,
    combosList: [],
    orderDetails: null,
    orderDetailsModal: false,
    totalOrderPaid: 0,
    stompClient: null,
    frontItemsList: null,
    savedQuotationPurchaseOrderId: null,
    savedQuotationsAutocomplete: [],
    websocketAddress: null,
    productsSearchType: null,
    ticketData: null,
    showDiscountInLine: false,
  },
  mutations: {
    SET_ATTRIBUTES(state, data) {
      state.attributes = data;
    },
    SET_PRODUCTS_AUTOCOMPLETE(state, payload) {
      state.productsList = payload;
    },
    SET_CLIENTS_AUTOCOMPLETE(state, payload) {
      state.clientsList = payload;
    },
    REMOVE_ITEM_FROM_FRONT_LIST(state, payload) {
      const newItemsList = state.itemsList.filter((x) => x.id !== payload.id
        && x.isGift !== 1);
      state.itemsList = newItemsList;
    },
    REMOVE_ITEM_FROM_LIST(state, payload) {
      const listItem = state.itemsList[payload];
      // Antes de eliminar un elemento del carrito, hay que ver si el item pertenece a un combo
      // ya que al quitarlo del carrito, el combo pierde validez
      // Si el item tiene un comboId, significa que pertence a un combo
      if (listItem.comboId) {
        // Buscamos el combo y si existe, se busca mediante el giftProductId del combo
        // todos los productos que tengan el mismo comboId y cuyo id sea igual al giftProductId
        // Esto es: en el carrito, obtener todos los items que nos regalan con el combo del producto
        // a elimnar.
        const combo = state.combosList.find((x) => x.comboId === listItem.comboId);
        if (combo) {
          const giftProducts = state.itemsList.filter((x) => x.comboId === combo.comboId
            && x.id === combo.giftProductId);

          // Por cada producto regalado que encontramos sacamos su indice, lo eliminamos del carrito
          // y en la lista de combos se resta una unidad a los productos regalados de ese combo
          giftProducts.forEach((item) => {
            const index = state.itemsList.indexOf(item);
            state.itemsList.splice(index, 1);
            combo.giftTotal -= 1;
          });
          // Tambien se resta un producto comprado al combo, ya que se quitará del carrito y
          // para que el combo no se quede en estatus de "completado"
          combo.boughtTotal -= 1;
        }
      }
      state.itemsList.splice(payload, 1);
    },
    ADD_ITEM_TO_LIST(state, payload) {
      const listItem = payload;
      state.itemsList.push(listItem);
    },
    PREVIEW_ITEM_INFO(state, payload) {
      state.itemToPreview = payload;
    },
    CLEAR_ITEMS_LIST(state) {
      state.itemsList = [];
    },
    CLEAR_ITEM_TO_PREVIEW(state) {
      state.itemToPreview = null;
    },
    SELECT_CLIENT(state, payload) {
      state.selectedClient = payload;
    },
    SET_AUTH_USER_DATA(state, payload) {
      state.authUserData = payload;
    },
    SET_TICKET_DATA(state, payload) {
      state.ticketData = payload;
    },
    CLEAR_ORDER(state) {
      state.itemToPreview = null;
      state.itemsList = [];
      state.selectedClient = null;
    },
    SET_CASH_CLOSE_DATA(state, payload) {
      state.cashCloseData = payload;
    },
    SHOW_ORDER_DETAILS(state, payload) {
      state.orderDetails = payload;
      state.orderDetailsModal = true;
    },
    CLOSE_MODAL(state) {
      state.orderDetailsModal = false;
    },
    UPDATE_TOTAL_ORDER_PAID(state, payload) {
      state.totalOrderPaid += payload;
    },
    CLEAR_TOTAL_ORDER_PAID(state) {
      state.totalOrderPaid = 0;
    },
    SET_STOMP_CLIENT(state, payload) {
      state.stompClient = payload;
    },
    REMOVE_FIXED_NUMBER_ITEMS_FROM_LIST(state, payload) {
      for (let i = 0; i < payload.quantity; i += 1) {
        for (let j = state.itemsList.length - 1; j >= 0; j -= 1) {
          if (state.itemsList[j].id === payload.id && state.itemsList[j].isGift !== 1) {
            state.itemsList.splice(j, 1);
            break;
          }
        }
      }
      const newItemsList = state.itemsList.filter((x) => x.isGift !== 1);
      state.itemsList = newItemsList;
    },
    CALCULATE_COMBOS(state) {
      state.itemsList.forEach(resetComboItems);

      const combos = getCombosFromItems(state.itemsList);
      let items = state.itemsList.map((item, index) => ({
        index,
        itemId: item.id,
        comboId: 0,
        isGift: false,
        completedComboId: null,
      }));

      let combosCount = 1;

      combos.forEach((combo) => {
        const unattachedItems = items.filter(({ comboId }) => comboId === 0);
        let completedCombos = extractCombosFromItems(combo, unattachedItems, []);
        completedCombos = completedCombos.filter((filterItem) => !!filterItem);

        // has completed combos, set comboId to items
        if (completedCombos.length > 0) {
          // set completed combo id to items
          completedCombos.forEach((item) => {
            item.items.forEach((item2) => setCompletedComboId(item2, combosCount));
            combosCount += 1;
          });

          const completedCombosItems = completedCombos.flatMap((item) => ([...item.items]));

          // must modify items by object reference in array
          completedCombosItems.forEach((item) => setComboId(item, combo.comboId));
        }
      });

      // remove unnecessary items
      items = items.filter((item) => !!item.completedComboId);

      let completedComboIds = new Set(items.map((item) => item.completedComboId));
      completedComboIds = Array.from(completedComboIds);

      // set combosList from items
      state.combosList = completedComboIds.map((comboId) => {
        const comboItems = items.filter((item) => item.completedComboId === comboId);

        const buyItems = comboItems.filter((item) => !item.isGift);
        const giftItems = comboItems.filter((item) => item.isGift);
        const { itemId: buyItemId } = buyItems[0];
        const { itemId: giftItemId } = buyItems[0];

        const buyAmount = buyItems.length;
        const giftAmount = giftItems.length;

        // eslint-disable-next-line max-len
        return new ComboListItem(comboId, buyItemId, giftItemId, buyAmount, giftAmount, buyAmount, giftAmount);
      });

      items.forEach((item) => {
        const { index, completedComboId, isGift } = item;

        const itemList = state.itemsList[index];
        itemList.comboId = completedComboId;
        itemList.isGift = isGift ? 1 : 0;
      });
    },
    LOAD_SAVED_QUOTATION_ITEMS(state, payload) {
      state.itemsList = payload.products;
      state.savedQuotationPurchaseOrderId = payload.id;
    },
    async CLEAR_SAVED_QUOTATION_ITEMS(state) {
      const { store } = state.authUserData.user_claims;
      const data = await PurchaseOrderService.getSavedQuotationsAutocompleteData(null, store);
      state.savedQuotationsAutocomplete = data;
      state.savedQuotationPurchaseOrderId = null;
    },
    SET_SAVED_QUOTATIONS_AUTOCOMPLETE(state, payload) {
      state.savedQuotationsAutocomplete = payload;
    },
    SET_PRINTER_IP_ADDRESS(state, payload) {
      state.websocketAddress = `ws://${payload}:15674/ws`;
    },
    CREATE_WEB_SOCKET(state) {
      const socket = new WebSocket(state.websocketAddress);
      const stompClient = Stomp.over(socket);
      stompClient.connect('admin', 'admin', this.onConnected, () => {
        Swal.fire('Error de conexión a impresora', 'No se pudo conectar a la PC a la que está'
          + ' conectada la impresora, revisa la IP en la sección "Conexión a impresora"'
          + ' del menú e intenta reconectar', 'error');
      }, '/');
      state.stompClient = stompClient;
    },
    ADD_SAVED_QUOTATION(state, payload) {
      state.savedQuotationsAutocomplete.push(payload);
    },
    SET_PRODUCTS_SEARCH_TYPE(state, payload) {
      state.productsSearchType = payload;
    },
    SET_SHOW_DISCOUNT_IN_LINE(state, payload) {
      state.showDiscountInLine = payload;
    },
  },
  actions: {
    setAttributes({ commit }, payload) {
      commit('SET_ATTRIBUTES', payload);
    },
    setProductsAutocomplete({ commit }, payload) {
      commit('SET_PRODUCTS_AUTOCOMPLETE', payload);
    },
    setClientsAutocomplete({ commit }, payload) {
      commit('SET_CLIENTS_AUTOCOMPLETE', payload);
    },
    removeItemFromList({ commit }, payload) {
      commit('REMOVE_ITEM_FROM_LIST', payload);
      commit('CLEAR_ITEM_TO_PREVIEW');
    },
    removeItemFromFrontList({ commit }, payload) {
      commit('REMOVE_ITEM_FROM_FRONT_LIST', payload);
      commit('CLEAR_ITEM_TO_PREVIEW');
    },
    addItemToList({ commit }, payload) {
      commit('ADD_ITEM_TO_LIST', payload);
      commit('PREVIEW_ITEM_INFO', payload);
    },
    previewItemInfo({ commit }, payload) {
      commit('PREVIEW_ITEM_INFO', payload);
    },
    clearItemsList({ commit }) {
      commit('CLEAR_ITEMS_LIST');
      commit('CLEAR_ITEM_TO_PREVIEW');
    },
    selectClient({ commit }, payload) {
      commit('SELECT_CLIENT', payload);
      commit('CLEAR_ITEMS_LIST');
      commit('CLEAR_ITEM_TO_PREVIEW');
    },
    setAuthUserData({ commit }, payload) {
      commit('SET_AUTH_USER_DATA', payload);
    },
    setTicketData({ commit }, payload) {
      commit('SET_TICKET_DATA', payload);
    },
    clearOrder({ commit }) {
      commit('CLEAR_ORDER');
    },
    setCashCloseData({ commit }, payload) {
      commit('SET_CASH_CLOSE_DATA', payload);
    },
    showOrderDetails({ commit }, payload) {
      commit('SHOW_ORDER_DETAILS', payload);
    },
    closeModal({ commit }) {
      commit('CLOSE_MODAL');
    },
    updateTotalOrderPaid({ commit }, payload) {
      commit('UPDATE_TOTAL_ORDER_PAID', payload);
    },
    clearTotalOrderPaid({ commit }) {
      commit('CLEAR_TOTAL_ORDER_PAID');
    },
    setStompClient({ commit }, payload) {
      commit('SET_STOMP_CLIENT', payload);
    },
    removeFixedNumberItemsFromList({ commit }, payload) {
      commit('REMOVE_FIXED_NUMBER_ITEMS_FROM_LIST', payload);
    },
    calculateCombos({ commit }) {
      commit('CALCULATE_COMBOS');
    },
    loadSavedQuotationItems({ commit }, payload) {
      commit('LOAD_SAVED_QUOTATION_ITEMS', payload);
    },
    clearSavedQuotationItems({ commit }) {
      commit('CLEAR_SAVED_QUOTATION_ITEMS');
    },
    setSavedQuotationsAutocomplete({ commit }, payload) {
      commit('SET_SAVED_QUOTATIONS_AUTOCOMPLETE', payload);
    },
    setPrinterIPAddress({ commit }, payload) {
      commit('SET_PRINTER_IP_ADDRESS', payload);
      commit('CREATE_WEB_SOCKET');
    },
    createWebSocket({ commit }) {
      commit('CREATE_WEB_SOCKET');
    },
    addSavedQuotation({ commit }) {
      commit('CLEAR_SAVED_QUOTATION_ITEMS');
    },
    setProductsSearchType({ commit }, payload) {
      commit('SET_PRODUCTS_SEARCH_TYPE', payload);
    },
    setShowDiscountInLine({ commit }, payload) {
      commit('SET_SHOW_DISCOUNT_IN_LINE', payload);
    },
  },
  getters: {
    productsList: (state) => state.productsList,
    itemsList: (state) => state.itemsList,
    frontItemsList: (state) => {
      state.itemsList.forEach(clearAlreadyInList);

      let frontItems = state.itemsList.map((x) => {
        let quantity = 0;
        if (!x.alreadyInList) {
          if (x.isGift === 1) {
            // eslint-disable-next-line max-len
            const itemsList = state.itemsList.filter((item) => item.id === x.id && item.isGift === 1);
            quantity = itemsList.length;
            itemsList.forEach(setAlreadyInList);
          } else {
            // get items if not gifts
            const totalItems = state.itemsList.filter((item) => x.id === item.id && !item.isGift);
            quantity = totalItems.length;
            totalItems.forEach(setAlreadyInList);
          }
          return makeFrontItemFromItem(x, quantity);
        }

        return null;
      });
      frontItems = frontItems.filter((item) => !!item);
      return frontItems;
    },
    cartTotal: (state) => {
      let total = state.itemsList.reduce((acc, item) => acc + item.baseCost, 0);
      const discounts = state.itemsList.map((item) => {
        // Porcentaje de descuento a usar
        let discountToUse = 0;

        // Si el producto es un regalo (Ej: regalo de combo), se le da el 100% de descuento
        if (item.isGift) {
          discountToUse = 100;
        } else if (item.discountSpecials.length > 0) {
          discountToUse = item.discountSpecials[0].discount;

          // Si el producto tiene descuento y el cliente seleccionado tambien
          // se evalua cual de los dos es el mayor y ese es el que se usará
          if (state.selectedClient && state.selectedClient.discount > 0) {
            if (state.selectedClient.discount > item.discountSpecials[0].discount) {
              discountToUse = state.selectedClient.discount;
            }
          }
        } else if (state.selectedClient && state.selectedClient.discount > 0) {
          // Si el producto no tiene descuento pero el cliente si,
          // se usará el descuento del cliente
          discountToUse = state.selectedClient.discount;
        }

        // Se obtiene el descuento total (en pesos) en base al porcentaje
        // y se multiplica por la cantidad de articulos ingresados para obtener el descuento total
        let discount = FPUtils.getItemDiscount(item.baseCost, discountToUse);
        discount *= item.quantity;

        const item2 = item;
        item2.calculatedDiscount = discount;

        return discount;
      });

      const discountsTotal = discounts.reduce((acc, discount) => acc + discount, 0);
      total -= discountsTotal;

      return {
        total,
        FTotal: CurrencyFormatter.format(total),
      };
    },
    selectedClient: (state) => state.selectedClient,
    itemToPreview: (state) => state.itemToPreview,
    clientsList: (state) => state.clientsList,
    authUserData: (state) => state.authUserData,
    ticketData: (state) => state.ticketData,
    cashCloseData: (state) => state.cashCloseData,
    orderDetails: (state) => state.orderDetails,
    orderDetailsModal: (state) => state.orderDetailsModal,
    totalOrderPaid: (state) => state.totalOrderPaid,
    FTotalOrderPaid: (state) => CurrencyFormatter.format(state.totalOrderPaid),
    stompClient: (state) => state.stompClient,
    savedQuotationPurchaseOrderId: (state) => state.savedQuotationPurchaseOrderId,
    savedQuotationsAutocomplete: (state) => state.savedQuotationsAutocomplete,
    websocketAddress: (state) => state.websocketAddress,
    productsSearchType: (state) => state.productsSearchType,
    showDiscountInLine: (state) => state.showDiscountInLine,

    attributes: (state) => state.attributes,

    canViewCarrito: (state) => state.attributes.includes('VIEW_POS_CAR'),
    canViewPos: (state) => state.attributes.includes('VIEW_POS_SYS'),
  },
});
