import CurrencyFormatter from './CurrencyFormatter';
import { PurchaseOrderProductItem } from './PurchaseOrder/PurchaseOrderProductItem';

export class PurchaseOrderSingleItem {
  constructor(id, employee, client, total, type, createdDt, isClosed, cashPayments,
    debitCardPayments, creditCardPayments, checkPayments, transferPayments, products,
    realCashPayments) {
    this.id = id;
    this.employee = employee;
    this.client = client;
    this.total = total;
    this.type = type;
    this.createdDt = createdDt;
    this.isClosed = isClosed;
    this.cashPayments = cashPayments;
    this.debitCardPayments = debitCardPayments;
    this.creditCardPayments = creditCardPayments;
    this.checkPayments = checkPayments;
    this.transferPayments = transferPayments;
    this.realCashPayments = realCashPayments;
    this.products = [];
    products.forEach((x) => {
      const item = new PurchaseOrderProductItem(x.costDiscount, x.currentCost, x.product, x.isGift);
      this.products.push(item);
    });
  }

  get employeeName() {
    if (this.employee) {
      return this.employee.user ? `${this.employee.user.firstname} ${this.employee.user.lastName}` : 'Sin vendedor';
    }
    return 'Sin vendedor';
  }

  get clientName() {
    return this.client ? this.client.name : 'Anónimo';
  }

  get createdDtStr() {
    const dt = this.createdDt.split('T')[0];
    const time = this.createdDt.split('T')[1];
    const splitTime = time.split(':');
    const splitDt = dt.split('-');
    return `${splitDt[2]}/${splitDt[1]}/${splitDt[0]} ${splitTime[0]}:${splitTime[1]}`;
  }

  // TODO: CUANDO SE AGREGUEN CREDITOS, PROBABLEMENTE ESTO VA A CAMBIAR
  get totalAmountStr() {
    return this.total
      ? CurrencyFormatter.format(this.total) : CurrencyFormatter.format(0);
  }

  get cashAmountStr() {
    let totalCashAmount = 0;
    if (this.cashPayments.length > 0) {
      this.cashPayments.forEach((x) => {
        totalCashAmount += x.amount;
      });
    }
    return CurrencyFormatter.format(totalCashAmount);
  }

  get realCashAmountStr() {
    let totalCashAmount = 0;
    if (this.realCashPayments.length > 0) {
      this.realCashPayments.forEach((x) => {
        totalCashAmount += x.amount;
      });
    }
    return CurrencyFormatter.format(totalCashAmount);
  }

  get changeAmountStr() {
    let totalChangeAmount = 0;
    if (this.cashPayments.length > 0) {
      this.cashPayments.forEach((x) => {
        totalChangeAmount += x.change;
      });
    }
    return CurrencyFormatter.format(totalChangeAmount);
  }

  get debitCardAmountStr() {
    let totalAmount = 0;
    if (this.debitCardPayments.length > 0) {
      this.debitCardPayments.forEach((x) => {
        totalAmount += x.amount;
      });
    }
    return CurrencyFormatter.format(totalAmount);
  }

  get creditCardAmountStr() {
    let totalAmount = 0;
    if (this.creditCardPayments.length > 0) {
      this.creditCardPayments.forEach((x) => {
        totalAmount += x.amount;
      });
    }
    return CurrencyFormatter.format(totalAmount);
  }

  get checkAmountStr() {
    let totalAmount = 0;
    if (this.checkPayments.length > 0) {
      this.checkPayments.forEach((x) => {
        totalAmount += x.amount;
      });
    }
    return CurrencyFormatter.format(totalAmount);
  }

  get transferAmountStr() {
    let totalAmount = 0;
    if (this.transferPayments.length > 0) {
      this.transferPayments.forEach((x) => {
        totalAmount += x.amount;
      });
    }
    return CurrencyFormatter.format(totalAmount);
  }

  get totalStr() {
    return CurrencyFormatter.format(this.total);
  }

  get totalCashAmountStr() {
    let totalCashAmount = 0;
    if (this.cashPayments.length > 0) {
      this.cashPayments.forEach((x) => {
        totalCashAmount += x.amount;
        totalCashAmount -= x.change;
      });
    }
    return CurrencyFormatter.format(totalCashAmount);
  }

  get getTicketData() {
    const orderProducts = [];
    this.products.forEach((x) => {
      orderProducts.push({
        name: x.product.name,
        cost: x.currentCostStr,
        discount: x.costDiscountStr,
      });
    });
    const data = {
      id: this.id,
      employee: this.employeeName,
      client: this.clientName,
      total: this.totalAmountStr,
      type: this.type,
      createdDt: this.createdDt,
      isClosed: this.isClosed,
      cashTotal: this.cashAmountStr,
      creditCardTotal: this.creditCardAmountStr,
      debitCardTotal: this.debitCardAmountStr,
      checkTotal: this.checkAmountStr,
      transferTotal: this.transferAmountStr,
      products: orderProducts,
      cashPaidTotal: this.cashAmountStr,
      changeTotal: this.changeAmountStr,
    };
    return data;
  }

  get productsData() {
    const newList = [];
    this.products.forEach((x) => {
      const item = x;
      item.alreadyInList = false;
    });
    this.products.forEach((x) => {
      let quantity = 0;
      if (!x.alreadyInList) {
        const totalItems = this.products.filter((item) => x.product.id === item.product.id);
        quantity = totalItems.length;
        totalItems.forEach((item) => {
          const listItem = item;
          listItem.alreadyInList = true;
        });
        const totalCost = x.currentCost * quantity;
        const costStr = CurrencyFormatter.format(totalCost);
        const totalDiscount = x.costDiscount * quantity;
        const discountStr = CurrencyFormatter.format(totalDiscount);
        const costWithDiscount = totalCost - totalDiscount;
        const costWithDiscountStr = CurrencyFormatter.format(costWithDiscount);
        newList.push({
          id: x.product.id,
          name: x.product.name,
          quantity,
          costStr,
          calculatedDiscount: totalDiscount,
          discountStr,
          isGift: x.isGift,
          costWithDiscountStr,
          model: x.product.model,
          baseCostStr: CurrencyFormatter.format(x.baseCost),
        });
      }
    });
    newList.forEach((x) => {
      const giftProducts = this.products.filter((p) => p.product.id === x.id
        && p.isGift === 1);
      const quantity = giftProducts.length;
      if (quantity > 0) {
        const product = giftProducts[0];
        const totalCost = product.currentCost * quantity;
        const costStr = CurrencyFormatter.format(totalCost);
        const totalDiscount = product.costDiscount * quantity;
        const discountStr = CurrencyFormatter.format(totalDiscount);
        const costWithDiscount = totalCost - totalDiscount;
        const costWithDiscountStr = CurrencyFormatter.format(costWithDiscount);
        newList.push({
          id: product.product.id,
          name: product.product.name,
          quantity,
          costStr,
          calculatedDiscount: totalDiscount,
          discountStr,
          isGift: product.isGift,
          costWithDiscountStr,
          model: product.product.model,
          baseCostStr: CurrencyFormatter.format(x.baseCost),
        });
      }
    });
    return newList;
  }
}
